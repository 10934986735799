.features-image {
	position: relative;
	margin-top: 64px;
	margin-bottom: 112px;
}

.features-illustration {
	position: absolute;
	top: -2%;
	left: 50%;
	transform: translateX(-50%);
	max-width: 136%;
}

.features-box {
	margin: 0 auto;
	box-shadow: 48px 16px 48px rgba(color(typography, 1), 0.12);
	transform: perspective(1000px) rotateY(10deg) translateY(2%);

	.lights-off & {
		box-shadow: 48px 16px 48px rgba(darken(color(bg, 1i), 35%), 0.2);
	}
}

.features-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-right: -12px;
	margin-left: -12px;
	margin-top: -12px;

	&:last-of-type {
		margin-bottom: -12px;
	}

	&:not(:last-of-type) {
		margin-bottom: 12px;
	}

	.feature {
		text-align: center;
		padding: 12px;
		width: 276px;
		max-width: 276px;
		flex-grow: 1;
	}
}

.feature-inner {
	position: relative;
	height: 100%;
	background: color(bg, 1);
	padding: 40px 24px;
	transition: all 0.3s ease;
	box-shadow: 0 0 16px 0 rgba(color(typography, 1), 0.1),
		0 8px 16px 0 rgba(color(typography, 1), 0.05),
		0 16px 32px 0 rgba(color(typography, 1), 0.05);

	&:hover {
		box-shadow: 4px 8px 16px 0 rgba(color(typography, 1), 0.1);
	}

	.lights-off & {
		background: color(bg, 3i);
		box-shadow: 0 0 16px 0 rgba(darken(color(bg, 3i), 35%), 0.2),
			0 8px 16px 0 rgba(darken(color(bg, 3i), 35%), 0.1),
			0 16px 32px 0 rgba(darken(color(bg, 3i), 35%), 0.1);
		&:hover {
			box-shadow: 4px 8px 16px 0 rgba(darken(color(bg, 3i), 35%), 0.2);
		}
	}

	.feature-icon {
		display: flex;
		justify-content: center;

		img,
		svg {
			max-width: none;
		}
	}

	.feature-title {
		position: relative;
		margin-bottom: 26px;

		&::after {
			content: "";
			width: 32px;
			height: 2px;
			position: absolute;
			bottom: -14px;
			left: calc(50% - 16px);
			background: color(bg, 3);

			.lights-off & {
				background: color(bg, 3i);
			}
		}
	}
}

@include media(">medium") {
	.features {
		position: relative;

		.section-paragraph {
			padding-left: 72px;
			padding-right: 72px;
		}

		&::before {
			content: "";
			width: 100%;
			height: 300px;
			position: absolute;
			left: 0;
			top: 168px;
			background: linear-gradient(
				to bottom,
				rgba(color(primary, 1), 0),
				rgba(color(primary, 1), 0.04)
			);

			.lights-off & {
				display: none;
			}
		}
	}

	.features-header {
		margin-bottom: 64px;
	}
}

// --------------------------------------------
// Colors -------------------------------------
// Usage example: color(primary, main)
// --------------------------------------------
$color: (
	typography: (
		1: #182538,
		2: #67768c,
		1i: #ffffff,
		2i: #8595ae
	),
	bg: (
		1: #ffffff,
		2: #f8f9fb,
		3: #e9edf3,
		1i: #182538,
		2i: #223146,
		3i: #304057
	),
	primary: (
		1: #535fd7,
		2: #7c85e1,
		3: #212880
	),
	secondary: (
		1: #61effd,
		2: #93f4fe,
		3: #2feafc
	),
	tertiary: (
		1: #ffbc48,
		2: #ffcf7b,
		3: #fe7e1f
	)
);

// --------------------------------------------
// Typography ---------------------------------
// --------------------------------------------
$font__family: (
	base: '"Heebo", sans-serif',
	// font-family(base)
		heading: '"IBM Plex Sans", sans-serif',
	// font-family(heading)
		code: 'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace',
	// font-family(code)
		pre: '"Courier 10 Pitch", Courier, monospace' // font-family(pre)
);

$font__sizes: (
	alpha:   ( 44px, 54px, 0px ),    // font-size, line-height, kerning (use '0' if don't want to output any kerning)
	beta:    ( 38px, 48px, -0.1px ),
	gamma:   ( 32px, 42px, -0.1px ),
	delta:   ( 24px, 34px, -0.1px ),
	epsilon: ( 20px, 30px, -0.1px ),
	zeta:    ( 18px, 27px, -0.1px ),
	eta:     ( 16px, 24px, -0.1px ),
	theta:   ( 14px, 20px, 0px )
);

$font__scale: (
	desktop: (
		// i.e. $breakpoint__m + $breakpoint__l (600 - 1024)
			1: map-get($font__sizes, alpha),
		// H1
			2: map-get($font__sizes, beta),
		// H2
			3: map-get($font__sizes, delta),
		// H3
			4: map-get($font__sizes, epsilon),
		// H4, H5, H6
			5: map-get($font__sizes, epsilon),
		// Body
			6: map-get($font__sizes, zeta),
		// Text small
			7: map-get($font__sizes, eta),
		// Text smaller
			8: map-get($font__sizes, theta) // Footer area
	),
	mobile: (
		// i.e. $breakpoint__xs + $breakpoint__s (up to 600)
			1: map-get($font__sizes, beta),
		// H1
			2: map-get($font__sizes, gamma),
		// H2
			3: map-get($font__sizes, delta),
		// H3
			4: map-get($font__sizes, epsilon),
		// H4, H5, H6
			5: map-get($font__sizes, epsilon),
		// Body
			6: map-get($font__sizes, zeta),
		// Text small
			7: map-get($font__sizes, eta),
		// Text smaller
			8: map-get($font__sizes, theta) // Footer area
	)
);

$font__weight: (
	regular: 400,
	// font__weight(regular)
		medium: 500,
	// font__weight(medium)
		semibold: 600,
	// font__weight(semibold)
		bold: 700 // font__weight(bold)
);

// --------------------------------------------
// Structure ----------------------------------
// --------------------------------------------
$content__padding: (
	mobile: 16px,
	desktop: 24px
);
$container__width: 1080px;
$container__width-sm: 800px;
